import * as React from 'react';
import Select from '@mui/joy/Select';
import Option, { optionClasses } from '@mui/joy/Option';
import Chip from '@mui/joy/Chip';
import List from '@mui/joy/List';
import ListItemDecorator, {
  listItemDecoratorClasses,
} from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';

export default function SelectGroupedOptions({ placeholder, label, options = {}, onChange, startDecorator }) {
  return (
    <Select
      {...{ onChange, placeholder, startDecorator }}
      sx={{ m: 2 }}
      slotProps={{
        listbox: {
          component: 'div',
          sx: {
            maxHeight: 240,
            overflow: 'auto',
            '--List-padding': '0px',
            '--ListItem-radius': '0px',
          },
        },
      }}
    >
      {options && Object.entries(options).map(([name, entries], index) => (
        <React.Fragment key={name}>
          {index !== 0 && <ListDivider role="none" />}
          <List
            aria-labelledby={`select-group-${name}`}
            sx={{ '--ListItemDecorator-size': '28px' }}
          >
            <ListItem id={`select-group-${name}`} sticky>
              <Typography level="body-xs" textTransform="uppercase">
                {name} ({entries?.length})
              </Typography>
            </ListItem>
            {entries?.length && entries.map(entry => (
              <Option
                key={`${name}:${entry.name}`}
                value={`${name}:${entry.name}`}
                label={
                  <React.Fragment>
                    <Chip
                      size="sm"
                      color="success"
                      sx={{ borderRadius: 'xs', mr: 1 }}
                    >
                      {name}
                    </Chip>{' '}
                    {entry.description}
                  </React.Fragment>
                }
                sx={{
                  [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]:
                  {
                    opacity: 1,
                  },
                }}
              >
                <ListItemDecorator sx={{ opacity: 0 }}>
                  <Check />
                </ListItemDecorator>
                {entry.description}
              </Option>
            ))}
          </List>
        </React.Fragment>
      ))}
    </Select>
  );
}