import React from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Tooltip from '../common/Tooltip';


export default function SelectAgent({ options, modelName, setModelName, setFunctionSupport, tooltip, ...rest }) {

  const handleOptionChange = (value) => {
    setModelName(value);
  };


  return (
    <Tooltip {...{ tooltip }} open={!!tooltip}>
      <Select value={modelName} onChange={(e, value) => handleOptionChange(value)} sx={{ mb: 2 }} {...{ ...rest }}>
        {Object.entries(options).map(([key, value]) => (
          <Option key={key} value={key}>{value.description} {!value.supportsFunctions && '(no function support)'}</Option>
        ))}
      </Select>
    </Tooltip>
  );

}