import React from 'react';
import Typography from '@mui/joy/Typography';
import CallIcon from '@mui/icons-material/Call';
import Tooltip from '../common/Tooltip';
import Link from '@mui/joy/Link';
import getUserLocale from 'get-user-locale';



export default function PhoneNumber({ tooltip, number }) {
  let displayNumber = `+${number}`;
  // This is horrid, do better with a worldwide int prefix stripper
  number && getUserLocale()?.match(/en-GB/i) && (displayNumber = number.replace(/^44/, '0'));
  return (<>
    <Tooltip {...{ tooltip }} open={!!tooltip}>
      <Typography level="h6" align="left">
      {number &&
          <>Agent live on&nbsp;
          <Link href={`tel:+${number}`}>
            <Typography color="success" startDecorator={(<CallIcon />)}>{displayNumber}</Typography>
          </Link>
        </>}
      {!number && (<>Agent not active</>)}
    </Typography>
    </Tooltip>
  </>);



};