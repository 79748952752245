import { useState } from 'react';
import { useAuth } from "./api/auth";
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import Interstitial from './components/pages/Interstitial.js';
import Login from './components/pages/Login';
import LlmPanel from './components/pages/LlmPanel.js';
import Header from './components/common/Header';
import HeroPage from './components/pages/HeroPage';
import Privacy from './components/pages/Privacy';
import ErrorAlert from './components/common/ErrorAlert';
import { useTheme } from '@mui/joy/styles';
import SwaggerPage from './components/pages/Swagger';


const materialTheme = materialExtendTheme();

const Home = ({ ...rest }) => (
  <Container sx={{ pt: 'var(--Header-height)', mt: 2 }}>
    <LlmPanel {...rest} />
  </Container>
);


export default function App() {

  const status = useAuth();
  const [error, setError] = useState('');
  const [inform, setInform] = useState('');
  const [email, setEmail] = useState('notSent');
  const messages = { error, setError, inform, setInform };
  const uiMode = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    // Double CSS wrapping because MUI Joy is nice, but not feature complete:
    //  https://mui.com/joy-ui/guides/using-joy-ui-and-material-ui-together/
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider
        // light is way more readable at xs so only honour pref at sm and above
        defaultMode={small ? uiMode : 'light'}
        disableTransitionOnChange
      >
        <CssBaseline />
        <GlobalStyles
          styles={{
            ':root': {
              '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
              '--Cover-width': '40vw', // must be `vw` only
              '--Form-maxWidth': '700px',
              '--Transition-duration': '0.4s', // set to `none` to disable transition
            },
          }}
        />

        {(status === "loading") && <></>}
        {(status !== "loading") &&
          <Router>
            <Header />
            <Routes>
              <Route path="/landing" element={
                status !== 'loggedIn'
                  ? <HeroPage {...messages} />
                  : <Navigate to="/" />
              } />
              <Route path="/login" element={
                status !== 'loggedIn'
                  ? <Login {...messages}  {...{ email, setEmail, status }} />
                  : <Navigate to="/" />
              } />
              <Route path="/interstitial" element={
                status !== 'loggedIn'
                  ? <Interstitial {...messages} />
                  : <Navigate to="/" />
              } />
              <Route path="/signup" element={
                status !== 'loggedIn'
                  ? <Login variant="signup" {...messages} {...{ email, setEmail, status }} />
                  : <Navigate to="/" />
              } />
              <Route path="/password-reset" element={
                status !== 'loggedIn' ?
                  <Login variant="lostPassword" {...messages} {...{ email, setEmail, status }} />
                  : <Navigate to="/" />
                } />
              <Route path="/" element={
                status === 'loggedIn'
                  ? <Home {...messages} />
                  : <Navigate to="/landing" />
              } />
              <Route path="/privacy" element={<Privacy {...messages} {...{ email, setEmail, status }} />} />
              <Route path="/api" element={<SwaggerPage {...messages} />} />
            </Routes>
          </Router>
        }
        <ErrorAlert {...messages} />
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );


}