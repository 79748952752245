import React, { useState, useEffect } from 'react';
import { storage } from '../../lib/firebase';
import { ref, getDownloadURL, listAll, uploadBytes } from 'firebase/storage';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Drawer from '@mui/joy/Drawer';
import DialogTitle from '@mui/joy/DialogTitle';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Fab from '@mui/material/Fab';

import LightbulbIcon from '@mui/icons-material/Lightbulb';

const directory = `/examples/data`;
const metaFile = '/examples/metadata.json';

const saveFile = async (fileName, data) => {
  try {
    const fileRef = ref(storage, `${fileName}`);
    const fileBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    await uploadBytes(fileRef, fileBlob);
  } catch (error) {
    console.error('Error saving file:', error);
  }
};

const readFile = async (fileName) => {
  const fileRef = ref(storage, `${fileName}`);
  const url = await getDownloadURL(fileRef);
  const response = await fetch(url);
  return await response.json();

};

const FileManager = ({ setData, setError, setOpen }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const fetchFiles = async () => {
      let metaData, listRef, result, contents, metaUpdated;
      try {
        metaData = await readFile(metaFile) || {};
      }
      catch (e) {
        metaData = {};
      }
      try {
        listRef = ref(storage, directory);
        result = await listAll(listRef);

        const fileList = result.items.map((item) =>
        ({
          fileName: item.name,
          name: metaData[item.name]?.name,
          description: metaData[item.name]?.description
        }));
        if (fileList.length !== Object.keys(metaData)) {
          metaUpdated = true;

          contents = await Promise.all(fileList.filter(f => !f.description)
            .map(async f => ({ fileName: f.fileName, ...(await readFile(`/${directory}/${f.fileName}`)) }))
          );
          contents
            .forEach(m => {
              metaData[m.fileName] = { name: m.name, description: m.description };
              let file = fileList.find(f => (f.fileName === m.fileName));
              file && Object.assign(file, metaData[m.fileName]);
            });

          setFiles(fileList);
          if (metaUpdated) {
            await saveFile(metaFile, metaData);
          }
        }
      } catch (error) {
        setError(`Error opening file: ${error.message}`);
        console.error('Error fetching files:', error);
      }

    };
    fetchFiles();
  }, []);



  const handleLoadFile = async (fileName, index) => {
    setLoading(index);
    let data = await readFile(`${directory}/${fileName}`);
    data && setData(data);
    setLoading(null);
    setOpen(false);
  };


  return (
    <Sheet
      variant="outlined"
      sx={{
        borderRadius: 'sm',
        p: 2,
        boxShadow: 'sm',
        '--Table-alternateRowBackground': 'rgba(var(--mui-palette-primary-main), 0.05)',
      }}
    >
      <Table
        aria-label="File List"
        sx={{
          '--TableCell-headingFontWeight': 'md',
          '--TableCell-horizontalPadding': '1rem',
          '--TableCell-verticalPadding': '0.5rem',
          '--TableHead-rowHeight': '2.5rem',
          '--TableRow-rowHeight': '2rem',
        }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th width="70%">Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={file.fileName}>
              <td>
                <Typography level="body" sx={{ textAlign: 'start', height: '100%' }}>
                  <b>{file.name}</b>
              </Typography></td>
              <td sx={{ overflow: 'wrap' }}>
                <Typography>{file.description}</Typography>
              </td>
              <td>
                <Button
                  loading={loading === index}
                  variant="solid"
                  color="primary"
                  onClick={() => handleLoadFile(file.fileName, index)}
                >
                  Load
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>

  );

};


export default function ExampleFiles({ setData, setError, disabled }) {
  const [open, setOpen] = useState(false);
  return !disabled && (
    <Box>
      <Fab color="primary" variant="extended" aria-label="examples" onClick={() => setOpen(true)}
        sx={{
          position: 'relative',
          bottom: 0,
          right: 0,
        }}
      >
        <LightbulbIcon /> Try an example
      </Fab>
      <Drawer
        size="lg"
        variant="plain"
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: 'transparent',
              p: { md: 3, sm: 0, pt: 3 },
              boxShadow: 'none',
            },
          },
        }}
      >
        {open && <Sheet
          sx={{
            borderRadius: 'md',
            mt: 4,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <ModalClose />
          <DialogTitle>Sample Agents</DialogTitle>
          <FileManager {...{ setData, setError, setOpen }} />
        </Sheet>}
      </Drawer>
    </Box>
  );
}


