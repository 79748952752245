import { useCallback, useEffect, useState, useRef } from 'react';
import { styled } from '@mui/joy/styles';
import { Button, Grid, Sheet } from '@mui/joy';
import { createAgent, listModels, updateAgent, deleteAgent } from '../../api/agent';
import FunctionCalls from '../functions/FunctionCalls';
import SelectAgent from '../agent/SelectAgent';
import SelectVoice from '../agent/SelectVoice';
import Transcript from '../agent/Transcript';
import TemperatureSlider from '../agent/TemperatureSlider';
import PromptInput from '../agent/PromptInput';
import AgentButton from '../agent/AgentButton';
import LocalFiles from '../agent/LocalFiles';
import ExampleFiles from '../agent/ExampleFiles';

const Item = styled(Sheet)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: 4,
  color: theme.vars.palette.text.secondary,
}));

function useComponentWillUnmount(cleanupCallback = () => { }) {
  const callbackRef = useRef(cleanupCallback);
  callbackRef.current = cleanupCallback; // always up to date
  useEffect(() => {
    return () => callbackRef.current();
  }, []);
}

const defaultOptions = {
  tts: {
    vendor: 'google',
    language: 'en-GB',
    voice: 'en-GB-Wavenet-A'
  },
  stt: {
    vendor: 'google',
    language: 'en-GB'
  }

};

export default function LlmPanel({ error, setError, inform, setInform, status, ...props }) {
  let [agent, setAgent] = useState({});
  let [agents, setAgents] = useState([]);
  let [changed, setChanged] = useState(false);
  let [functions, setFunctions] = useState([]);
  let [apiKeys, setApiKeys] = useState([]);
  let [functionSupport, setFunctionSupport] = useState(false);
  let [language, setLanguage] = useState(defaultOptions.tts.language);
  let [modelName, setModelName] = useState();
  let [options, setOptions] = useState(defaultOptions);
  let [prompt, setPrompt] = useState({});
  let [state, setState] = useState('initial');
  let [temperature, setTemperature] = useState(0.2);
  let [tooltip, setTooltip] = useState({});
  let [transcript, setTranscript] = useState();
  let [voice, setVoice] = useState(defaultOptions.tts.voice);
  let [ws, setWs] = useState();
  let [metaData, setMetaData] = useState({ name: '', description: '' });

  useEffect(() => {
    const tryFetch = () => {
      !agents.length && listModels()
        .then(a => {
          setAgents(Object.fromEntries(a));
          setError(null);
        })
        .catch(err => {
          setError(`Couldn't communicate with server: ${err.message}`);
          setTimeout(tryFetch, 10000);
        });
    };
    tryFetch();
  }, []);

  useEffect(() => {
    if (status !== 'loggedIn') {
      disconnect();
    }
    return () => {
      disconnect();
    };
  }, [status]);


  useEffect(() => {
    if (!ws && state === 'active') {
      setError('Server disconnected, re-create agent to continue');
      disconnect();
    }
  }, [ws, state]);

  // manange progressive tooltip first time through UI
  useEffect(() => {
    if (!tooltip.done) {
      !modelName && setTooltip({
        selectAgent: { step: 1, title: 'Select model', text: 'This is the AI provider model that your agent will run' }
      });
      modelName && !prompt.changed && setTooltip({
        promptInput: { step: 2, title: 'Write your prompt', text: 'Personalise your agent here, the pre-filled text is a test prompt with a structure that we know works. Build your own following the structure or try something completely different!' }
      });
      modelName && prompt.changed && state === 'initial' && setTooltip({
        agentButton: {
          step: 3, title: 'Create agent', text: 'Set things up to call your agent'
        }
      });
      state === 'active' && !transcript.length && setTooltip({
        phoneNumber: { step: 4, title: 'Call the number', text: 'Grab a phone and call this number to test your agent\'s response' }
      });
      state === 'active' && transcript.length && options.tts.voice === defaultOptions.tts.voice && setTooltip({
        selectVoice: { step: 5, title: 'Change voices', text: 'Customise the voice your agent uses' }
      });
      state === 'active' && transcript.length && options.tts.voice !== defaultOptions.tts.voice && setTooltip({
        done: true,
      });

    }
  }, [modelName, prompt, state, transcript, options]);

  useEffect(() => {
    let [provider, voiceName] = voice?.split(':') || [];
    language && voiceName && provider && setOptions(o =>({
      ...o,
      tts: {
        voice: voiceName,
        vendor: provider
      },
      stt: {
        language
      }
    }));

  }, [language, voice]);

  useEffect(() => {
    if (state === 'active' && agent?.id) {
      updateAgent({ id: agent.id, options: { ...options } });
    }
  }, [options, state, agent]);

  useEffect(() => {
    console.log({ agents, modelName, agent: agents[modelName], functions: agents[modelName]?.supportsFunctions }, 'useEffect modelname');
    setFunctionSupport(agents?.[modelName]?.supportsFunctions);
  }, [modelName, agents]);


  const onMessage = useCallback((message) => {
    setTranscript((t) => ([...t, message]));
  }, []);

  const buttonClick = async () => {
    try {
      if (state === 'initial' && !agent?.id) {
        setState('trying');

        let res = await createAgent({ modelName, prompt: prompt.value, options: { ...options, temperature }, functions: functionSupport && functions, keys: apiKeys, onMessage, onClose: () => setWs(null) });
        setPrompt({ ...prompt });
        setChanged(false);
        setWs(res.ws);
        setAgent(res);
        setState('active');
        setTranscript([]);
        setInform({ success: `Agent created and handling calls to +${res?.number}` });

      }
      else if (state === 'active' && agent?.id) {
        await updateAgent({ id: agent.id, prompt: prompt.value, options: { ...options, temperature }, functions: functionSupport && functions, keys: apiKeys });
        setChanged(false);
        setInform({ success: 'Agent updated, will take effect from start of next call' });
      }
    }
    catch (err) {
      setState('initial');
      setError(`Couldn't ${state === 'initial' ? 'create' : 'update'} agent: ${err?.response?.data?.message || err.message}`);
    }
  };

  const snapshot = useCallback(() => ({ ...metaData, prompt, modelName, language, voice, functions, keys: apiKeys }), [metaData, prompt, modelName, language, voice, functions, apiKeys]);

  const restore = useCallback((data) => {
    data.prompt && setPrompt({
      value: data.prompt.value,
      changed: true,
      changedSinceCreate: true
    });
    data.modelName && setModelName(data.modelName);
    data.language && setLanguage(data.language);
    data.voice && setVoice(data.voice);
    data.functions && setFunctions(data.functions);
    data.keys && setApiKeys(data.keys);
    data.name && data.description && setMetaData({ name: data.name, description: data.description });
  }, []);

  const disconnect = useCallback(async () => {
    if (state === 'active' && agent?.id) {
      setState('trying');
      await deleteAgent({ id: agent.id });
      setAgent(null);
      setWs(null);
      setState('initial');
    }
  }, [state, agent]);

  useComponentWillUnmount(() => disconnect());
  console.log({apiKeys}, 'llm render')

  return (
    <>
      <Grid container spacing={2} sx={{ flexGrow: 1, width: '100%' }}>
        <Grid xs={12} sm={6}>
          <Item>
            <SelectAgent
              disabled={state !== 'initial'}
              options={agents}
              placeholder="Select model"
              tooltip={tooltip.selectAgent}
              {...{ modelName, setModelName, setFunctionSupport }}
            />
          </Item>
          <Item>
            <Grid container xs={12} sm={12}>
              <Grid xs={12} md={8}>
                <TemperatureSlider value={temperature} setValue={setTemperature} />
              </Grid>
              <Grid xs={12} md={4} alignContent="center">
                <FunctionCalls {...{ functions, setFunctions, apiKeys, setApiKeys}} disabled={!functionSupport} sx={{ width: '100%', ml: 3 }} />
              </Grid>
            </Grid>
          </Item>
        </Grid>
        <Grid container xs={12} sm={6}>
          <Grid xs={12} md={6}>
            <Item>
              <AgentButton
                disabled={state === 'trying' || !modelName
                  || (state === 'active' && !changed)
                }
                onClick={buttonClick} state={state}
                tooltip={tooltip.agentButton}
                sx={{ width: '100%' }}
              />
            </Item>
          </Grid>
          <Grid xs={12} md={6}>
            <Item>
              {state === 'active' ?
                (<Button disabled={state !== 'active'} color="danger" onClick={disconnect} sx={{ width: '100%' }}>
                  Disconnect Agent
                </Button>) :
                (<LocalFiles
                  getData={snapshot}
                  setData={restore}
                  sx={{ width: '100%' }}
                  {...{ setError }} />)
              }
            </Item>
          </Grid>
          <Grid container xs={12}>
            <SelectVoice
              {...{ language, setLanguage, voice, setVoice }}
              tooltip={tooltip.selectVoice}
            />
          </Grid>
        </Grid>
        <Grid xs={12} sm={6}>
          <Item>
            <PromptInput
              prompt={prompt}
              setPrompt={(prompt) => {
                setPrompt(prompt);
                setChanged(true);
              }}
              modelName={modelName}
              agents={agents}
              tooltip={tooltip.promptInput}
              endDecorator={<ExampleFiles setData={restore} setError={setError} disabled={prompt?.value?.length >= 100} />} /> 
          </Item>

        </Grid>
        <Grid xs={12} sm={6}>
          <Item>
            <Transcript transcript={transcript} number={agent?.number} tooltip={tooltip.phoneNumber} />
          </Item>
        </Grid>

      </Grid>


    </>
  );
};